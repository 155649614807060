@import "common/variables";

.login {

    h1 a {
        background-image: url(../images/mrprofiles-logo.svg);
        -webkit-background-size: 231px 79px;
        background-size: 231px 79px;
        width: 231px;
        height: 79px;
    }

    .button-primary {
        background-color: $brand-primary !important;
        border-color: darken($brand-primary, 10%);
        box-shadow: none;

        &, &:hover, &:active, &:focus {
            color: #fff;
            text-shadow: none;
        }

        &:hover {
            background-color: darken($brand-primary, 5%) !important;
            border-color: darken($brand-primary, 20%);
            box-shadow: none;
        }

        &:active,
        &:focus {
            background-color: darken($brand-primary, 5%) !important;
            border-color: darken($brand-primary, 20%);
            box-shadow: inset 0 3px 3px -1px rgba(0,0,0,0.25);
        }
    }
}